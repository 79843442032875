<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialog"
    :visible.sync="dialog"
    :title="title"
    width="1000px"
  >
    <el-form
      ref="elform"
      :model="formData"
      :rules="rules"
      label-position="right"
      label-width="120px"
      hide-required-asterisk
    >
      <el-form-item label="描述" prop="desc">
        <el-input
          style="width: 370px;"
          v-model="formData.desc"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        label="分类"
        prop="category"
      >
        <el-select
          v-model="formData.category"
          placeholder="请选择"
          style="width: 370px;"
        >
          <el-option
            v-for="(item,index) in categoryList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="收件人"
        prop="fromEmail"
      >
        <div class="type-check">
          <el-radio v-model="formData.recipientsType" :label="1">类型</el-radio>
          <el-radio v-model="formData.recipientsType" :label="2">自定义</el-radio>
        </div>
      </el-form-item>
      <el-form-item
      >
        <div class="select-type-module">
          <el-row :gutter="10">
            <el-col :span="22">
              <el-form-item v-if="formData.recipientsType == 1" prop="typeValue">
                <el-cascader
                  v-model="formData.typeValue"
                  style="width: 100%;"
                  placeholder="请选择"
                  :options="cascaderOption"
                  :props="props"
                  clearable
                ></el-cascader>
              </el-form-item>
              <el-form-item v-if="formData.recipientsType == 2" prop="selfTypeValue">
                <el-select
                  style="width: 100%;"
                  multiple
                  v-model="formData.selfTypeValue"
                  filterable
				  remote
				  placeholder="请选择"
				  :remote-method="remoteMethod"
				  :loading="remoteLoading"
				  :reserve-keyword="true"
                >
                  <el-option
                    v-for="(item,index) in rsAllUserOptions"
                    :key="index"
                    :label="getLabel(item)"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2">共{{countComputed}}人</el-col>
          </el-row>
        </div>
      </el-form-item>
      <el-form-item label="内容">
        <el-input
          type="textarea"
          maxlength="200"
          rows="8"
          show-word-limit
          v-model="formData.mainBodyContent"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="btn-module">
      <el-button type="text" @click="cancleHandle">取消</el-button>
      <el-button type="primary" :loading="saveLoading" @click="saveHandle">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import quillEditor from '@/components/tools/quillEditor'
  import { queryRsAllEMail, queryEMailTemplateList,queryMassDetail, addMass,editMass, computedReceiversCount } from '@/api/notificationManager/mass'
  import { parseTime } from '@/utils/index'

  export default {
    components: {
      quillEditor
    },
    props: {
      isAdd: {
        type: Boolean,
        default: false
      },
      rowId:{
        type: Number,
        default: 0,
      }
    },
    data() {
      let typeValueValidate = (rule,value,callback) => {
        if(value && value.length){
          callback();
        }else {
          callback(new Error('请选择'));
        }
      };
      let selfTypeValueValidate = (rule,value,callback) => {
        if(value && value.length){
          callback();
        }else {
          callback(new Error('请选择'));
        }
      };
      return {
        dialog: false,
        formData: {
          desc: '',
          category: 1,
          recipientsType: 1,
          typeValue: [],// 类型收件人,
          selfTypeValue: [], // 自定义收件人
          mainBodyContent: ''
        },
        rules: {
          desc: [
            { required: true, message: '请输入描述', trigger: 'blur', }
          ],
          typeValue:[
            { validator: typeValueValidate, trigger: 'change',}
          ],
          selfTypeValue:[
            { validator: selfTypeValueValidate, trigger: 'change',},
          ],
          mainBodyContent: '',
        },
        categoryList: [
          {
            label: '代办',
            value: 1,
          }
        ],
        cascaderOption: [
          {
            value: 99,
            label: '状态',
            children: [
              {
                value: 10,
                label: '注册未激活'
              },
              {
                value: 1,
                label: '注册未申请'
              },
              {
                value: 2,
                label: '申请未开户'
              },
              {
                value: 3,
                label: '开户未入金'
              },
              {
                value: 4,
                label: '入金未交易'
              },
              {
                value: 5,
                label: '已交易'
              }
            ]
          }, {
            value: 100,
            label: '身份',
            children: [
              {
                value: 1,
                label: '客户1（无上级代理，无客户经理）'
              },
              {
                value: 2,
                label: '客户2（无上级代理，有客户经理）'
              },
              {
                value: 3,
                label: '客户3（有上级代理，有客户经理）'
              },
              {
                value: 6,
                label: '客户4（有上级客户，无客户经理）'
              },
              {
                value: 4,
                label: '代理1（主代理）'
              },
              {
                value: 5,
                label: '代理2（子代理）'
              }
            ]
          }],
        props: { multiple: true },
        rsAllUserOptions: [],
        emialTemplateOptions: [],
        selfPriorityOption: [
          {
            value: 1,
            label: 1,
          },
          {
            value: 2,
            label: 2,
          },
          {
            value: 3,
            label: 3,
          }
        ],
        count: 0,
        saveLoading: false,
		remoteLoading: false,
      }
    },
    created() {
      this.getEmailTemplateList()
    },
    computed: {
      title() {
        return this.isAdd ? '新增' : '编辑'
      },
      countComputed(){
        return this.formData.recipientsType == 1 ? this.count: this.formData.selfTypeValue.length
      }
    },
    watch:{
      dialog(newValue){
        if(newValue && this.rowId && !this.isAdd){
          this.getData();
        }
      },
      'formData.recipientsType'(newValue){
        if(newValue){
          // 没有效果 todo
          this.$nextTick(() => {
            this.$refs.elform.validateField(['typeValue','selfTypeValue']);
          })
        }
      },
      'formData.typeValue'(){
        this.getRecipientsCount();
      },
      'formData.selfTypeValue'(){
        this.getRecipientsCount();
      },
      isAdd(newValue){
        if(newValue){
          this.reset();
        }
      }
    },
    methods: {
      parseTime,
      close(){
        this.dialog = false;
      },
	  remoteMethod(query){
	    if (query !== '') {
	      this.remoteLoading = true;
	      queryRsAllEMail({
	        userInfo: query,
	      }).then((res) => {
	        this.remoteLoading = false;
	        this.rsAllUserOptions = res
	      });
	    } else {
	      this.rsAllUserOptions = [];
	    }
	  },
      getData(){
        queryMassDetail({id: this.rowId}).then((res) => {
          this.id = res.id;
          this.formData.desc = res.describe;
          this.formData.recipientsType = res.receiverSource;

          if(res.receiverSource == 1){
            this.formData.typeValue = [];
            if(res.custStatus){
              let custStatusArr = res.custStatus.split(',');
              for (let i = 0; i < custStatusArr.length; i++) {
                this.formData.typeValue.push([99,+custStatusArr[i]]);
              };
            }
            if(res.custIdentity){
              let custIdentityArr = res.custIdentity.split(',');
              for (let i = 0; i < custIdentityArr.length; i++) {
                this.formData.typeValue.push([100,+custIdentityArr[i]]);
              };
            }
          }else {
            this.formData.selfTypeValue = res.toList;
          }
          this.count = res.dueCount;
          this.formData.mainBodyContent = res.content;
        })
      },
      elCascaderRemoveHandle(){
        this.getRecipientsCount();
      },
      getRecipientsCount() {
        if(this.formData.recipientsType == 1){
          if(this.formData.typeValue.length){
            let statusList = this.geCommon(99);
            let identityList = this.geCommon(100);
            let params = {}
            if(statusList.length){
              params['statusList'] = statusList;
            }
            if(identityList.length){
              params['identityList'] = identityList;
            }
            computedReceiversCount(params).then((res) => {
              this.count = res;
            })
          }else {
            this.count = 0;
          }
        }else {
          if(typeof this.formData.selfTypeValue  == 'array'){
            this.count = this.formData.selfTypeValue.length;
          }else {
            this.count = 0;
          }
        }
      },
      getEmailTemplateList() {
        queryEMailTemplateList().then((res) => {
          this.emialTemplateOptions = res
        })
      },
      getLabel(item){
        return `${item.id} (${item.userName})`
      },
      cancleHandle(){
        this.close();
      },
      geCommon(number){
        let tempArr = [];
        for (let i = 0; i < this.formData.typeValue.length ; i++) {
          if(this.formData.typeValue[i][0] == number){
            tempArr.push(this.formData.typeValue[i][1]);
          }
        }
        return tempArr;
      },
      getStatusList(){
        return this.geCommon(99);
      },
      getIdentityListList(){
        return this.geCommon(100);
      },
      reset(){
        this.formData = {
          desc: '',
          category: 1,
          recipientsType: 1,
          typeValue: [],// 类型收件人,
          selfTypeValue: [], // 自定义收件人
          mainBodyContent: '',
        };
      },
      saveHandle(){
        this.$refs.elform.validate((valid) => {
          if(valid){
            if(!this.formData.mainBodyContent){
              this.$message({
                type: 'warning',
                message: '请在富文本输入内容',
              })
              return;
            }
            this.saveLoading = true;
            let params = {
              type: 2,
              classification: 1,
              describe: this.formData.desc,
              emailConfigId: this.formData.emailConfigId,
              receiverSource: this.formData.recipientsType,
              dueCount: this.countComputed,
            }
            if(this.formData.recipientsType == 1){
              if(this.getStatusList().length){
                params.statusList = this.getStatusList();
              }
              if(this.getIdentityListList().length){
                params.identityList = this.getIdentityListList();
              }
            }else {
              params.toList = this.formData.selfTypeValue;
            }
            params.content = this.formData.mainBodyContent;
            if(!this.isAdd && this.rowId){
              params.id = this.rowId;
              editMass(params).then((res) => {
                this.saveLoading = false;
                this.$message({
                  type: 'success',
                  message: '保存成功',
                });
                this.$parent.init();
                setTimeout(() => {
                  this.close();
                  this.reset();
                },1000)
              }).catch((error) => {
                this.saveLoading = false;
              });
            }else {
              addMass(params).then((res) => {
                this.saveLoading = false;
                this.$message({
                  type: 'success',
                  message: '保存成功',
                });
                this.$parent.init();
                setTimeout(() => {
                  this.close();
                  this.reset();
                },1000)
              }).catch((error) => {
                this.saveLoading = false;
              });
            }
          }else {
            console.log('不通过');
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
