<template>
  <el-dialog
    :visible.sync="dialog"
    append-to-body
    title="查看"
    width="1000px"
  >
    <el-form
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="描述">
        <div style="width: 370px;" class="similar-input">
          {{formData.desc}}
        </div>
      </el-form-item>
      <el-form-item
        label="发件人"
      >
        <div style="width: 370px;" class="similar-input">
          {{formData.sender}}
        </div>
      </el-form-item>
      <el-form-item label="收件人">
          <el-row :gutter="10">
            <el-col :span="20">
              <div class="similar-input content">
                {{formData.toList}}
              </div>
            </el-col>
            <el-col :span="2">
              <div class="recipients">共{{count}}人</div>
            </el-col>
          </el-row>
      </el-form-item>
      <el-form-item label="邮件标题">
        <el-row :gutter="10">
          <el-col :span="12">
            <div class="similar-input content">
              {{formData.title}}
            </div>
          </el-col>
          <el-col :span="10">
            <el-form-item label="优先级">
              <div class="similar-input">{{formData.priority}}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="内容">
        <div class="similar-input content overflow-auto" v-html="formData.mainBodyContent"></div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import { queryMassDetail } from '@/api/notificationManager/mass'
  export default {
    name: 'emailLookDetail',
    props: {
      rowId:{
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        formData: {
          desc:'',
          priority: '',
          mainBodyContent: ''
        },
        dialog: false,
        count: 0,
      }
    },
    watch:{
      dialog(newValue){
        if(newValue){
          this.getData();
        }
      }
    },
    methods: {
      close(){
        this.dialog = false;
      },
      getData(){
        queryMassDetail({id: this.rowId}).then((res) => {
          this.id = res.id;
          this.formData.desc = res.describe;
          this.formData.sender = res.sender;
          if(res.toList.length){
            this.formData.toList = res.toList.join('、');
          }
          this.formData.title = res.title;
          this.formData.priority = res.priority;
          this.count = res.dueCount;
          this.formData.mainBodyContent = res.content;
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .content {
    min-height: 32px;
    height: auto;
  }
</style>
