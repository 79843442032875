<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="query.type"
            clearable placeholder="类型"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in queryTypeOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-select
            v-model="query.status"
            clearable
            placeholder="状态"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in statusOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-input
            v-model="query.createUser"
            clearable
            placeholder="创建人"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="toQuery"
    />

    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="addEmailHandle">新增邮件</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="addMessageHandle">新增站内信</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      queryTypeOptions: [
        { key: 1, display_name: '邮件' },
        { key: 2, display_name: '站内信(代办)' },
      ],
      statusOptions: [
        { key: 1, display_name: '未提交' },
        { key: 2, display_name: '待审核' },
        { key: 3, display_name: '审核通过' },
        { key: 4, display_name: '审核拒绝' },
      ],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    addMessageHandle(){
      this.$emit('add-message');
    },
    addEmailHandle(){
      this.$emit('add-mail');
    },
    clear(){
      this.$emit('clear');
    }
  }
}
</script>
