<template>
  <el-dialog
    :visible.sync="dialog"
    append-to-body
    title="查看"
    width="1000px"
  >
    <el-form
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="描述">
        <div style="width: 370px;" class="similar-input">
          {{formData.desc}}
        </div>
      </el-form-item>
      <el-form-item
        label="分类"
      >
        <div style="width: 370px;" class="similar-input">
          {{formData.fromEmail}}
        </div>
      </el-form-item>
      <el-form-item label="收件人">
        <template v-if="formData.receiverSource == 1">
          <el-row :gutter="10">
            <el-col :span="20">
              <div class="similar-input">
                来源: {{formData.recipientsEmail}}
              </div>
            </el-col>
            <el-col :span="2">
              <div class="recipients">共{{count}}人</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <div class="similar-input status-similar-input content">
                状态:{{formData.statusTotalText}}
              </div>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <el-row :gutter="10">
            <el-col :span="20">
              <div class="similar-input">
                {{formData.recipientsEmail}}
              </div>
            </el-col>
            <el-col :span="2">
              <div class="recipients">共{{count}}人</div>
            </el-col>
          </el-row>
        </template>
      </el-form-item>
      <el-form-item label="内容">
        <div class="similar-input content" v-html="formData.mainBodyContent"></div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import { queryMassDetail } from '@/api/notificationManager/mass'
  import auditBtn from '@/components/notificationManager/massAudit/auditBtn'
  export default {
    name: 'messageAuditDetail',
    components:{
      auditBtn,
    },
    props: {
      rowId:{
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        formData: {
          desc:'',
          fromEmail:'代办',
          totalText: '',

          recipientsCount: '',
          recipientsEmail: '',
          mailSourceText: '',
          priority: '',
          mainBodyContent: ''
        },
        dialog: false,
        count: 0,
      }
    },
    watch:{
      dialog(newValue){
        if(newValue){
          this.getData();
        }
      }
    },
    methods: {
      close(){
        this.dialog = false;
      },
      getData(){
        queryMassDetail({id: this.rowId}).then((res) => {
          this.id = res.id;
          this.formData.desc = res.describe;
          this.formData.emailConfigId = res.emailConfigId;
          this.formData.receiverSource = res.receiverSource;

          if(res.receiverSource == 1){
            if(res.custStatus){
              let matchTextArr = [{
                value: 10,
                label: '注册未激活'
              },
                {
                  value: 1,
                  label: '注册未申请'
                },
                {
                  value: 2,
                  label: '申请未开户'
                },
                {
                  value: 3,
                  label: '开户未入金'
                },
                {
                  value: 4,
                  label: '入金未交易'
                },
                {
                  value: 5,
                  label: '已交易'
                }];
              let tempTotalText = [];
              let custStatusArr = res.custStatus.split(',');
              for (let i = 0; i < custStatusArr.length; i++) {
                for (let j = 0; j < matchTextArr.length; j++) {
                  if(custStatusArr[i] == matchTextArr[j].value){
                    tempTotalText.push(matchTextArr[j].label);
                  }
                }
              };
              if(tempTotalText.length){
                this.formData.totalText = tempTotalText.join(',')
              }
            }
            if(res.custIdentity){
              let matchTextArr = [
                {
                  value: 1,
                  label: '客户1（无上级代理，无客户经理）'
                },
                {
                  value: 2,
                  label: '客户2（无上级代理，有客户经理）'
                },
                {
                  value: 3,
                  label: '客户3（有上级代理，有客户经理）'
                },
                {
                  value: 4,
                  label: '代理1（主代理）'
                },
                {
                  value: 5,
                  label: '代理2（子代理）'
                }
              ];
              let tempTotalText = [];
              let custStatusArr = res.custIdentity.split(',');
              for (let i = 0; i < custStatusArr.length; i++) {
                for (let j = 0; j < matchTextArr.length; j++) {
                  if(custStatusArr[i] == matchTextArr[j].value){
                    tempTotalText.push(matchTextArr[j].label);
                  }
                }
              };
              if(tempTotalText.length){
                this.formData.statusTotalText = tempTotalText.join(',')
              }
            }
            this.formData.priority = res.priority;
          }else {
            if(res.toList.length){
              this.formData.recipientsEmail = res.toList.join(',');
            }
          }
          this.count = res.dueCount;
          this.formData.mainBodyContent = res.content;
        })
      },
      ajaxListHandle(){
        this.close();
        this.$parent.init();
      }
    }
  }
</script>

<style lang="less" scoped>
  .content {
    min-height: 32px;
    height: auto;
  }
</style>
