<template>
  <div class="app-container">
    <eHeader
      :query="query"
      @add-message="addMessageHandle"
      @add-mail="addMailHandle"
      @toQuery="toQuery"
      @clear="clear"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="群发ID"/>
      <el-table-column prop="userId" label="类型">
        <template slot-scope="scope">
          <span>{{getType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createUser" label="创建人"/>
      <el-table-column prop="createUser" label="发送人">
        <template slot-scope="scope">
          <span>{{getSender(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="describe" label="描述"/>
      <el-table-column label="通知情况">
        <template slot-scope="scope">
          <div>
            <span>{{scope.row.successCount}}</span>
            /
            <span>{{scope.row.dueCount}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="scope">
          <div>
            {{parseTime(scope.row.updateTime)}}
          </div>
        </template>
      </el-table-column>
      <!-- 需显示上级名称 -->
      <el-table-column label="状态">
        <template slot-scope="scope">
          <div class="">{{getStatus(scope.row)}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="getBtnFlag(scope.row)"
            @click="editOrLookHandle(scope.row)"
            size="mini"
            type="success"
          >{{getEditOrLookText(scope.row)}}</el-button>
          <el-button
            :loading="btnLoading"
            v-if="getLookLogFlag(scope.row)"
            @click="lookLogHandle(scope.row)"
            size="mini"
            type="primary"
          >群发记录</el-button>
          <el-button
                  class="margin-right5"
            v-if="getSubmitBtnFlag(scope.row)"
            size="mini" @click="submitHandle(scope.row)"
            type="danger"
          >提交</el-button>
          <el-popover
            v-if="getDeleteBtnFlag(scope.row)"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="deleteLoading" type="primary" size="mini" @click="deleteHandle(scope.row)">确定</el-button>
            </div>
            <el-button
              slot="reference"
              size="mini"
              type="danger"
            >删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <message-dialog
      ref="messagedialog"
      :is-add="isAdd"
      :row-id="currentRowId"
    ></message-dialog>
    <mail-dialog
      ref="maildialog"
      :is-add="isAdd"
      :row-id="currentRowId"
    ></mail-dialog>
    <email-look-detail
      ref="emaillookdetail"
      :row-id="currentRowId"
    ></email-look-detail>
    <message-look-detail
      ref="messagelookdetail"
      :row-id="currentRowId"
    ></message-look-detail>
  </div>
</template>

<script>
import { deleteMass,submitMass } from '@/api/notificationManager/mass'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/notificationManager/mass/header';
import messageDialog from '@/components/notificationManager/mass/messageDialog';
import mailDialog from '@/components/notificationManager/mass/mailDialog';
import emailLookDetail from '@/components/notificationManager/mass/emailLookDetail';
import messageLookDetail from '@/components/notificationManager/mass/messageLookDetail';
export default {
  name:'mass',
  components: {
    eHeader,
    messageDialog,
    mailDialog,
    emailLookDetail,
    messageLookDetail,
  },
  mixins: [initData],
  data() {
    return {
      query:{
        createUser: '',
        type: '',
        status: '',
      },
      delLoading: false,
      btnLoading: false,
      isAdd: true,
      currentRowId: 0,
      deleteLoading: false,
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'crm/sysSendMailGroup'
      const sort = 'id,desc'
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
      }
     if (this.query.createUser) {
        this.params.createUser = this.query.createUser;
     }
      if (this.query.type) {
        this.params.type = this.query.type;
      }
      if (this.query.status !== '') {
        this.params.status = this.query.status;
      }
      return true
    },
    detailHandle(row){
      this.$refs.detaildialog.dialog = true;
    },
    resendHandle(id){
      this.btnLoading = true;
      resendEmail({id: id}).then((res) => {
        this.btnLoading = false;
        this.$message({
          message: '重发成功',
          type: 'success'
        });
      }).catch((error) => {
        this.btnLoading = false;
      })
    },
    getFlag(row){
      return checkPermission(['ADMIN','SysSendMailQuartz_ALL','SysSendMailQuartz_EDIT']) && (row.status === 2);
    },
    cancleHandle(row){
      this.btnLoading = true;
      updateStatus({
        id: row.id,
        status: 3,
      }).then((res) => {
        this.btnLoading = false;
        this.$message({
          message: '操作成功'
        });
        this.init();
      }).catch((error) => {
        this.btnLoading = false;
      })
    },
    addMessageHandle(){
      this.isAdd = true;
      this.$refs.messagedialog.dialog = true;
    },
    addMailHandle(){
      this.isAdd = true;
      this.$refs.maildialog.dialog = true;
    },
    getType(row){
      if(row.type == 1){
        return '邮件';
      }else {
        let inner = '';
        switch (row.classification) {
          case 1:
            inner = '代办';
            break;
          case 2:
            inner = '账户';
            break;
          case 3:
            inner =  '代办';
            break;
        }
        return `站内信(${inner})`;
      }
    },
    getSender(row){
      if(row.type == 2){
        return 'Maxain 官方';
      }else {
        return row.fromEmail;
      }
    },
    getStatus(row){
      switch (row.status) {
        case 1:
          return '未提交';
        case 2:
          return '待审核';
        case 3:
          return '审核通过';
        case 4:
          return '审核拒绝';
      }
    },
    getEditOrLookText(row){
      if(row.status ==3){
        return '查看';
      }else {
        return '编辑';
      }
    },
    editOrLookHandle(row){
      this.currentRowId = row.id;
      if(row.status ==3){
        //区分站内信和邮件
        if(row.type ==1){
          this.$refs.emaillookdetail.dialog = true;
        }else {
          this.$refs.messagelookdetail.dialog = true;
        }
      }else {
        //区分站内信和邮件
        this.isAdd = false;
        if(row.type ==1){
          this.$refs.maildialog.dialog = true;
        }else {
          this.$refs.messagedialog.dialog = true;
        }
      }
    },
    getBtnFlag(row){
      return checkPermission(['ADMIN','SysSendMailGroup_ALL','SysSendMailGroup_SELECT'
      ]) && row.status != 2;
    },
    getSubmitBtnFlag(row){
      return checkPermission(['ADMIN','SysSendMailGroup_ALL','SysSendMailGroup_SELECT'
      ]) && row.status != 2 && row.status != 3;
    },
    getDeleteBtnFlag(row){
      return checkPermission(['ADMIN','SysSendMailGroup_ALL','SysSendMailGroup_SELECT'
      ]) && row.status != 2 && row.status != 3;
    },
    getLookLogFlag(row){
      return  checkPermission(['ADMIN','SysSendMailGroup_ALL','SysSendMailGroup_SELECT'
      ]) && row.status == 3;
    },
    lookLogHandle(row){
      this.$store.commit('setMessageOrMailRecordId', row.id);
      if(row.type == 1){
        this.$router.push({name: 'emailLog'});
      }else {
        this.$router.push({name: 'messageLog'});
      }
    },
    submitHandle(row){
      this.btnLoading = true;
      this.$confirm('是否提交', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        submitMass({id: row.id}).then((res) => {
          this.btnLoading = false;
          this.$message({
            type: 'success',
            message: '提交成功',
          });
          setTimeout(() => {
            this.init();
          },1000);
        }).catch((error) => {
          this.btnLoading = false;
        })
      }).catch(() => {
        this.btnLoading = false;
      });
    },
    deleteHandle(row){
      this.deleteLoading = true;
      deleteMass({id: row.id}).then((res) => {
        this.deleteLoading = false;
        this.$refs[row.id].doClose()
        this.$message({
          type: 'success',
          message: '删除成功',
        });
        setTimeout(() => {
          this.init();
        },1000)
      }).catch((error) => {
        this.deleteLoading = false;
        this.$refs[id].doClose();
        console.log(err.response.data.message)
      })
    },
    clear(){
      this.query = {};
      this.toQuery();
    }
  }
}
</script>

<style scoped>

</style>
